import React from "react";
import freelancing from "../../assets/how to work/freelancing.png";
import jobemploy from "../../assets/how to work/jobempoly.png";
import student from "../../assets/how to work/students.png";
import housewife from "../../assets/how to work/housewifes.png";

export default function HowToWork() {
  return (
    <div>
      <h2 className="pt-5 pb-5 lg:text-6xl md:text-5xl sm:text-3xl text-3xl tracking-wide font-semibold text-[#FF6700] underline text-center">
        এই কোর্স যাদের জন্য
      </h2>
      <div className=" flex gap-5 justify-center flex-wrap py-10">
        {/* single software */}
        <div className="flex flex-col gap-3 items-center shadow-md p-5 rounded-md">
          <img src={freelancing} alt="freelancing" />
          <li className=" text-2xl font-extrabold tracking-wide text-[#292828c3]">
            ফ্রিল্যান্সিং এ আগ্রহী
          </li>
        </div>
        {/* single software */}
        <div className="flex flex-col gap-3 items-center shadow-md p-5 rounded-md flex-wrap">
          <img src={jobemploy} alt="jobempoly" />
          <li className=" text-2xl font-extrabold tracking-wide text-[#292828c3]">
            চাকুরী প্রত্যাশী
          </li>
        </div>
        <div className="flex flex-col gap-3 items-center shadow-md p-5 rounded-md">
          <img src={student} alt="student" />
          <li className=" text-2xl font-extrabold tracking-wide text-[#292828c3]">
            ছাত্র-ছাত্রী
          </li>
        </div>
        <div className="flex flex-col gap-3 items-center shadow-md p-5 rounded-md">
          <img src={housewife} alt="housewife" />
          <li className=" text-2xl font-extrabold tracking-wide text-[#292828c3]">
            গৃহিণী
          </li>
        </div>
      </div>
    </div>
  );
}
