import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-hot-toast";

export default function Registration() {
  const [registerInfo, setRegisterInfo] = useState(null);

  const handelRegister = async (e) => {
    e.preventDefault();
    const form = e.target;
    await axios({
      method: "POST",
      url: "https://epd-website-project-server.vercel.app/register",
      data: registerInfo,
    })
      .then(({ data }) => {
        toast.success(data.message);
        form.reset();
      })
      .catch((error) => toast.error(error.message));
  };
  const handelInput = (e) => {
    setRegisterInfo((prv) => {
      return { ...prv, [e.target.name]: e.target.value };
    });
  };
  return (
    <div className="lg:w-[1140px] lg:mx-auto md:w-full sm:w-full py-20 lg:px-0 md:px-2 sm:px-2 px-2">
      <h2 className="text-5xl font-semibold capitalize tracking-wide  text-[#FF6700] lg:px-0 md:px-2 sm:px-2 px-2 underline text-center">
        Registration
      </h2>
      <form
        onSubmit={handelRegister}
        className="registration_filed pt-10 flex flex-col"
      >
        <input
          onChange={handelInput}
          type="text"
          name="name"
          id="name"
          placeholder="Name"
          className="border-b-2 border-[#FF6700] lg:w-1/2 md:w-[70%] px-5 py-2 bg-transparent outline-none mb-5 translate-y-[-2] focus:translate-y-2 duration-100 transition-all"
        />
        <input
          onChange={handelInput}
          type="email"
          name="email"
          id="email"
          placeholder="Email"
          className="border-b-2 border-[#FF6700] lg:w-1/2 md:w-[70%]  px-5 py-2 bg-transparent outline-none mb-5 translate-y-[-2] focus:translate-y-2 duration-100 transition-all"
        />
        <input
          onChange={handelInput}
          type="number"
          name="number"
          id="phone"
          placeholder="Phone Number"
          className="border-b-2 border-[#FF6700] lg:w-1/2 md:w-[70%] px-5 py-2 bg-transparent outline-none mb-5 translate-y-[-2] focus:translate-y-2 duration-100 transition-all"
        />
        <div className="pt-5">
          <select
            onChange={handelInput}
            className="lg:w-1/2 md:w-[70%] w-full px-5 py-2 bg-transparent border-b-2 border-[#FF6700] translate-y-[-2] focus:translate-y-2 duration-100 transition-all"
            name="services"
            id="services"
          >
            <option value="Select Your Services" selected>
              Select Your Services
            </option>
            <option value="Professional Web Design">
              Professional Web Design
            </option>
            <option value="Web Development"> Web Development</option>
            <option value="Mern Stack Development">
              Mern Stack Development
            </option>
            <option value="Font And Development With React">
              Font And Development With React
            </option>
            <option value="Graphics Design"> Graphics Design</option>
            <option value="AUTO CAD 2D/3D"> AUTO CAD 2D/3D</option>
            <option value="Architecture Modeling And Visualisation">
              Architecture Modeling And Visualisation
            </option>
            <option value="Practical Estimation">Practical Estimation</option>
            <option value="Revit Architecture BIM Modeling">
              Revit Architecture BIM Modeling
            </option>
            <option value="RCC Structural Design, Anlysis & Detailing">
              RCC Structural Design, Anlysis & Detailing
            </option>
          </select>
        </div>
        <div className="button">
          <button className="py-2 px-24 bg-[#FF6700] rounded-md mt-10 focus:bg-[#FF6730]">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
