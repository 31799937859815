import React from "react";

import Navbar from "./Navbar";
import HeaderTop from "./HeaderTop/HeaderTop";

export default function Header() {
  return (
    <div className=" sticky top-0 z-30">
      <div className="wrapper">
        <HeaderTop />
        <div className="header">
          <div className=" shadow-md ">
            <div className="navbar  py-3 lg:w-[1140px] lg:mx-auto md:w-full sm:w-full w-full">
              <Navbar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
