import React from "react";
import { Link } from "react-router-dom";
import ContactInfo from "./ContactInfo";

export default function Contact() {
  return (
    <div>
      {/* contact */}
      <div className="about py-20">
        <Link to="/contact">
          <h1 className="text-5xl font-semibold capitalize tracking-wide text-start text-[#FF6700] lg:px-0 md:px-0 sm:px-2 px-2 underline">
            Contact Me -
          </h1>
        </Link>
      </div>
      {/* contact */}
      {/* contact Info */}
      <ContactInfo />
      {/* contact Info */}
    </div>
  );
}
