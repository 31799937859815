import React from "react";

import Epdgroup from "./Epdgroup";
import EpdSolution from "./EpdSolution";
import EpdSector from "./EpdSector";
import FooterHeader from "../../Components/Footer/FooterHeader";

export default function About() {
  return (
    <div>
      <div className="lg:w-[1140px] lg:mx-auto md:w-full sm:w-full w-full py-20">
        <div className="about ">
          <h1 className="text-5xl font-semibold capitalize tracking-wide text-start text-[#FF6700] lg:px-0 md:px-2 sm:px-2 px-2 underline">
            About Me -
          </h1>
        </div>
        {/* epd_group */}
        <Epdgroup />
        {/* epd_group */}
        {/* epd solution */}
        <EpdSolution />
        {/* epd solution */}
        {/* epd it */}
        <EpdSector />
        {/* epd it */}
      </div>
    </div>
  );
}
