import React from "react";
import it from "../../assets/epd group/it.jpg";
import { Link } from "react-router-dom";

export default function EpdSector() {
  return (
    <div>
      {/* epd group */}
      <div className="epd_wrapper py-5 lg:flex md:flex">
        {/* left */}
        <div className="epd_left lg:w-[30%] lg:pr-20 md:px-10 lg:px-0  sm:px-2 px-2 pb-10 md:w-[30%]">
          <img className=" rounded-md" src={it} alt="epd-group" />
        </div>
        {/* right */}
        <div className="epd_right flex-1">
          <Link to="/epdIndustrial" target="blank">
            <h2 className="text-5xl text-[#FF6700] underline font-semibold tracking-wide pb-5 lg:px-0 md:px-0 sm:px-2 px-2">
              EPD INDUSTRIAL AUTOMATION & ENGINEERING
            </h2>
          </Link>
          <p className=" text-justify tracking-wide lg:px-0 md:px-0 sm:px-2 px-2 lg:pr-2 md:pr-2 sm:pr-2 pr-2">
            We provide Consultancy Services in Operation and Maintenance of
            Automation system & profefessional training
          </p>
          <p className=" text-justify tracking-wide lg:px-0 md:px-0 sm:px-2 px-2 lg:pr-2 md:pr-2 sm:pr-2 pr-2">
            The Group Has Come A Long Way In Accomplishing Its Goals By
            Listening To Our Valuable Client Requirements, Learning Real-Time
            Lessons From Past Projects, Innovating And Partnering Its Project
            Implementation Process. During Major Investment Undertakings In All
            Sectors,EPD Group Has Meaningfully Contributed To The Country’s
            Economic Stability In Financial Markets. Most Of Its Projects Have
            Been Success Stories – This Fact Alone Is Enough To Validate A Sense
            Of Confidence In The Group’s Future
          </p>

          <div className=" w-[95%] mx-auto h-[2px] bg-[#FF6700] mt-10 lg:px-0 md:px-0 sm:px-2 px-2"></div>
          {/* Experience */}
          <div className="flex gap-16 pt-10">
            <div className="flex items-center ">
              <h1 className=" lg:text-9xl md:text-9xl sm:text-5xl text-5xl pr-5 font-semibold text-[#FF6700] lg:px-0 md:px-0 sm:px-2 px-2">
                7
              </h1>
              <p className="lg:text-3xl md:text-3xl sm:text-lg text-lg font-bold text-[#FF6700]">
                Years of Experience
              </p>
            </div>
            <div className="flex items-center">
              <h2 className=" lg:text-9xl md:text-9xl sm:text-5xl text-5xl pr-5 font-semibold text-[#FF6700] lg:px-0 md:px-0 sm:px-2 px-2">
                32
              </h2>
              <p className="lg:text-3xl md:text-3xl sm:text-lg text-lg font-bold text-[#FF6700]">
                Awards & Honors
              </p>
            </div>
          </div>
          {/* Experience */}
        </div>
        {/* right */}
      </div>
      {/* epd group */}
    </div>
  );
}
