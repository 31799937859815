import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import "./styles.css";

// import required modules
import { Navigation } from "swiper";
// images
import web from "../../assets/course/development.jpg";
import webdev from "../../assets/course/arcitecture.jpg";
import hero from "../../assets/course/mern.jpg";
import rivit from "../../assets/epd group/project4.avif";
import civil from "../../assets/course/react.jpg";

export default function HeroSection() {
  return (
    <div>
      <>
        <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
          <SwiperSlide>
            <div>
              <img
                className="h-[70vh] w-full object-cover"
                src={civil}
                alt=""
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <img className="h-[70vh] w-full object-cover" src={web} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <img
                className="h-[70vh] w-full object-cover"
                src={webdev}
                alt=""
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <img className="h-[70vh] w-full object-cover" src={hero} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <img
                className="h-[70vh] w-full object-cover"
                src={rivit}
                alt=""
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </>
    </div>
  );
}
