import React, { useState } from "react";
import { toast } from "react-hot-toast";

export default function ContactInput() {
  const [inputInfo, setInputInfo] = useState(null);

  const handelInput = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch(`https://epd-website-project-server.vercel.app/message`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(inputInfo),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          form.reset();
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  const handelChange = (e) => {
    setInputInfo((prv) => {
      return { ...prv, [e.target.name]: e.target.value };
    });
  };
  return (
    <div className="lg:px-0 md:px-2 sm:px-2 px-2 relative py-10">
      <p className="pt-5 tracking-wide">
        You can directly visit our office to contact for any need. Moreover, you
        can call the hotline number to know any information related to training.
        You can also knock on the mentioned mail or Facebook messenger.
      </p>
      {/* contact from */}
      <form onSubmit={handelInput} className="">
        <div className="input_filed pt-10 ">
          <div className="pt-1 ">
            <input
              className="outline-none w-full  bg-transparent border-b-2 border-[#FF6700] placeholder:text-[#FF6700] text-lg translate-y-[-2] focus:translate-y-2 duration-100 transition-all "
              type="text"
              name="name"
              placeholder="Name"
              onChange={handelChange}
              required
            />
          </div>
        </div>
        {/*  */}
        <div className="input_filed pt-5">
          <div className="pt-5 w-full">
            <input
              className="outline-none w-full  bg-transparent border-b-2 border-[#FF6700] placeholder:text-[#FF6700] text-lg translate-y-[-2] focus:translate-y-2 duration-100 transition-all"
              type="email"
              name="email"
              placeholder="Email"
              onChange={handelChange}
              required
            />
          </div>
        </div>
        {/*  */}
        {/*  */}
        <div className="input_filed pt-5">
          <div className="pt-5 w-full">
            <input
              className="outline-none w-full  bg-transparent border-b-2 border-[#FF6700] placeholder:text-[#FF6700] text-lg translate-y-[-2] focus:translate-y-2 duration-100 transition-all"
              type="message"
              name="message"
              placeholder="Message"
              onChange={handelChange}
              required
            />
          </div>
        </div>
        {/*  */}
        {/* button */}
        <div className="btn mt-10">
          <button className=" text-xl font-semibold bg-[#FF6700] px-20 py-2 rounded-md tracking-wide">
            Submit
          </button>
        </div>
        {/* button */}
      </form>
      {/* contact from */}
    </div>
  );
}
