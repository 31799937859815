import HeroSection from "../HeroSection/HeroSection";
import About from "../About/About";
import Contact from "../About/Contact/Contact";
import ContactInput from "../ContactPage/ContactInput";
import { useEffect, useState } from "react";
import { BsFillArrowUpCircleFill } from "react-icons/bs";
import FooterBottm from "../../Components/Footer/FooterBottm";
import FooterHeader from "../../Components/Footer/FooterHeader";

export default function Home() {
  // scroll
  const [bottomScroll, setBottomScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setBottomScroll(true);
      } else {
        setBottomScroll(false);
      }
    });
  }, []);
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // scroll
  return (
    <div className="all_homePages">
      <div className="lg:w-[1140px] lg:mx-auto md:w-full sm:w-full">
        <HeroSection />
        <About />

        <Contact />
        <ContactInput />
        {bottomScroll && (
          <BsFillArrowUpCircleFill
            onClick={scrollUp}
            className="   right-3 text-[#001efff0] cursor-pointer bottom-0 z-[100] text-4xl fixed "
          />
        )}
      </div>
      <FooterHeader />
    </div>
  );
}
