import React from "react";
import { Link } from "react-router-dom";
import project1 from "../../assets/epd group/project1.avif";
import project2 from "../../assets/epd group/project2.avif";
import project3 from "../../assets/epd group/project3.avif";
import project4 from "../../assets/epd group/project4.avif";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper";
import { LiaHandPointRight } from "react-icons/lia";
import FooterHeader from "../../Components/Footer/FooterHeader";

export default function EpdProjectDevelopMent() {
  return (
    <div>
      <div className="lg:w-[1140px] lg:mx-auto md:w-full sm:w-full w-full pb-20 pt-5 lg:px-0 md:px-2 sm:px-2 px-2">
        {/* cover */}
        <div className="img">
          <>
            <Swiper
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div>
                  <img
                    className="h-[70vh] w-full object-cover"
                    src={project1}
                    alt=""
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    className="h-[70vh] w-full object-cover"
                    src={project2}
                    alt=""
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    className="h-[70vh] w-full object-cover"
                    src={project3}
                    alt=""
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    className="h-[70vh] w-full object-cover"
                    src={project4}
                    alt=""
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </>
        </div>
        {/* cover */}
        <h2 className="text-center tracking-wide lg:text-5xl md:text-4xl sm:text-3xl text-3xl text-[#FF6700] font-semibold py-10">
          Engineering Project Development
        </h2>
        {/* our course */}
        <div className=" shadow-md shadow-[#FF6700] py-10 px-5 rounded-md mt-5">
          <h2 className="text-5xl font-semibold capitalize tracking-wide text-start text-[#FF6700] lg:px-0 md:px-2 sm:px-2 px-2 underline">
            Our Services
          </h2>
          <div className="pt-5 lg:flex md:flex gap-10">
            <div className="">
              <li className=" flex gap-1 pb-2">
                <LiaHandPointRight size="25px" color="#FF6700" />
                <p className=" tracking-wide text-lg">
                  Architectural & Structural Drawing
                </p>
              </li>
              <li className=" flex gap-1 pb-2">
                <LiaHandPointRight size="25px" color="#FF6700" />
                <p className=" tracking-wide text-lg"> Structural Design</p>
              </li>
              <li className=" flex gap-1 pb-2">
                <LiaHandPointRight size="25px" color="#FF6700" />
                <p className=" tracking-wide text-lg">Interiors Design</p>
              </li>
              <li className=" flex gap-1 pb-2">
                <LiaHandPointRight size="25px" color="#FF6700" />
                <p className=" tracking-wide text-lg">Bank Valuations</p>
              </li>
              <li className=" flex gap-1 pb-2">
                <LiaHandPointRight size="25px" color="#FF6700" />
                <p className=" tracking-wide text-lg"> Site Supervision</p>
              </li>
            </div>
            {/* end */}
            <div>
              <li className=" flex gap-1 pb-2">
                <LiaHandPointRight size="25px" color="#FF6700" />
                <p className=" tracking-wide text-lg">
                  {" "}
                  Electrical & Plumbing Design
                </p>
              </li>
              <li className=" flex gap-1 pb-2">
                <LiaHandPointRight size="25px" color="#FF6700" />
                <p className=" tracking-wide text-lg"> BOQ & Estimate</p>
              </li>
              <li className=" flex gap-1 pb-2">
                <LiaHandPointRight size="25px" color="#FF6700" />
                <p className=" tracking-wide text-lg"> Field Survey</p>
              </li>
              <li className=" flex gap-1 pb-2">
                <LiaHandPointRight size="25px" color="#FF6700" />
                <p className=" tracking-wide text-lg"> Planing</p>
              </li>
              <li className=" flex gap-1 pb-2">
                <LiaHandPointRight size="25px" color="#FF6700" />
                <p className=" tracking-wide text-lg"> Soil Test</p>
              </li>
            </div>
            {/* end */}
          </div>
        </div>
        {/* our course */}
        {/* industrial services */}
        <div className=" shadow-md shadow-[#FF6700] py-10 px-5 rounded-md mt-10">
          <h2 className="text-3xl font-semibold capitalize tracking-wide text-start text-[#FF6700] lg:px-0 md:px-2 sm:px-2 px-2 underline">
            Course Plan For Industrial Attachment
          </h2>
          {/* course Plan */}
          <div className="pt-5">
            <Link to="/courses">
              <li className=" hover:text-[#FF6700] duration-300 cursor-pointer flex gap-1 pb-2">
                <LiaHandPointRight size="25px" color="#FF6700" />
                <p className="tracking-wide text-lg underline">
                  {" "}
                  AUTO CAD 2D/3D
                </p>
              </li>
            </Link>
            <Link to="/courses">
              <li className=" hover:text-[#FF6700] duration-300 cursor-pointer flex gap-1 pb-2">
                <LiaHandPointRight size="25px" color="#FF6700" />
                <p className="tracking-wide text-lg underline">
                  Architecture Modeling And Visualisation
                </p>
              </li>
            </Link>
            <Link to="/courses">
              <li className=" hover:text-[#FF6700] duration-300 cursor-pointer flex gap-1 pb-2">
                <LiaHandPointRight size="25px" color="#FF6700" />
                <p className="tracking-wide text-lg underline">
                  {" "}
                  Practical Estimation
                </p>
              </li>
            </Link>
            <Link to="/courses">
              {" "}
              <li className=" hover:text-[#FF6700] duration-300 cursor-pointer flex gap-1 pb-2">
                <LiaHandPointRight size="25px" color="#FF6700" />
                <p className="tracking-wide text-lg underline">
                  {" "}
                  Revit Architecture BIM Modeling
                </p>
              </li>
            </Link>
            <Link to="/courses">
              <li className=" hover:text-[#FF6700] duration-300 cursor-pointer flex gap-1 pb-2">
                <LiaHandPointRight size="25px" color="#FF6700" />
                <p className="tracking-wide text-lg underline">
                  {" "}
                  RCC Structural Design, Anlysis & Detailing
                </p>
              </li>
            </Link>
            <Link to="/courses">
              <li className=" hover:text-[#FF6700] duration-300 cursor-pointer flex gap-1 pb-2">
                <LiaHandPointRight size="25px" color="#FF6700" />
                <p className="tracking-wide text-lg underline">
                  {" "}
                  Site Supervision
                </p>
              </li>
            </Link>
            <Link to="/courses">
              <li className=" hover:text-[#FF6700] duration-300 cursor-pointer flex gap-1 pb-2">
                <LiaHandPointRight size="25px" color="#FF6700" />
                <p className="tracking-wide text-lg underline">
                  {" "}
                  Project Presentation
                </p>
              </li>
            </Link>
          </div>
        </div>
        {/* industrial services */}
      </div>
      <FooterHeader />
    </div>
  );
}
