import { RouterProvider } from "react-router-dom";
import { router } from "./Router/router";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="root_layout">
      <>
        <RouterProvider router={router}></RouterProvider>
      </>
    </div>
  );
}

export default App;
