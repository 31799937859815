import React from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

export default function HeaderTop() {
  return (
    <div className="">
      {/* contact  */}
      <div className="bg-[#FF6700]">
        <div className="header_top lg:w-[1140px] lg:mx-auto md:w-full sm:w-full w-full">
          <div className="phone py-3 bg-[#FF6700] lg:flex md:flex gap-5">
            {/* phone */}
            <p className="lg:flex md:flex sm:flex flex lg:justify-start md:justify-center justify-center items-center ml-2 ">
              <BsFillTelephoneFill className="text-white" />
              <span className="lg:pl-5 md:pl-3 sm:pl-2 pl-2 text-white font-semibold tracking-wide">
                01600002422
              </span>
            </p>
            {/* phone */}
            {/* email */}
            <p className="lg:flex md:flex sm:flex flex lg:justify-start md:justify-start justify-center  items-center">
              <MdEmail className="text-white" />
              <span className="lg:pl-5 md:pl-3 sm:pl-2 pl-2 text-white font-semibold tracking-wide">
                epditsolution@gmail.com
              </span>
            </p>
            {/* email */}
          </div>
        </div>
      </div>
      {/* contact  */}
    </div>
  );
}
