import React from "react";
import { GoLocation } from "react-icons/go";
import { BsTelephonePlus } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import FooterBottm from "./FooterBottm";
import { Link } from "react-router-dom";

export default function FooterHeader() {
  return (
    <div className="bg-[#292929e3]">
      <div className="lg:w-[1140px] mx-auto lg:px-0 md:px-3 px-3 py-10 cursor-pointer ">
        <div className="lg:flex lg:justify-between gap-1 py-3 text-white ">
          <div className="contact lg:pb-0  pb-10">
            <h1 className="text-3xl font-sans font-extrabold">Location</h1>
            <div className="location flex gap-4 py-5">
              <div className="location_logo ">
                <GoLocation size="30px" />
              </div>
              <div>
                <li className="text-lg font-sans">
                  <span className=" font-bold"> Main Branch:</span> Aftab Uddin
                  Bhuiya road, West vhuruliya, Gazipur -1707
                </li>
                <li className="text-lg font-sans">
                  <span className=" font-bold">Faridpur Branch:</span> Puratan
                  Passport Office Mor Jhiltuli, Faridpur
                </li>
              </div>
            </div>
            <div className="phone flex gap-4 pb-5">
              <div className="phone_log">
                <BsTelephonePlus size="30px" />
              </div>
              <div>
                <li className="text-lg font-sans">01600002422</li>
                <li className="text-lg font-sans">01862309427</li>
                <li className="text-lg font-sans">01734129265</li>
              </div>
            </div>
            <div className="email flex gap-4 items-center">
              <div className="email_log ">
                <AiOutlineMail size="30px" />
              </div>{" "}
              <div>
                <li className="text-lg font-sans">epditsolution@gmail.com</li>
                <li className="text-lg font-sans">epd2k16ltd@gmail.com</li>
              </div>
            </div>
          </div>
          <div className="popular_course lg:pb-0 pb-10">
            <h2 className="text-3xl font-sans pb-2 font-extrabold">Courses</h2>
            <Link to="/course" target="blank">
              <li className="text-lg font-sans pb-2">Web Development</li>
            </Link>
            <Link to="/course" target="blank">
              <li className="text-lg font-sans pb-2">
                Professional Graphic Design
              </li>
            </Link>
            <Link to="/course" target="blank">
              <li className="text-lg font-sans pb-2">Mern Stack Development</li>
            </Link>
            <Link to="/course" target="blank">
              <li className="text-lg font-sans pb-2">
                Professional AutoCAD MasterCourse (Civil & Architectural)
              </li>
            </Link>
          </div>
          <div className="other">
            <h2 className="text-3xl font-sans pb-2 font-extrabold">Others</h2>
            <Link to="/about" target="blank">
              <li className="text-lg font-sans pb-2">About Us</li>
            </Link>
            <Link to="/course" target="blank">
              <li className="text-lg font-sans pb-2">Our Course</li>
            </Link>
            <Link to="/freelancing" target="blank">
              <li className="text-lg font-sans pb-2">Freelancing</li>
            </Link>
          </div>
        </div>
        <FooterBottm />
      </div>
    </div>
  );
}
