import React from "react";

import Course from "../Course/Course";

export default function EpdItServices() {
  return (
    <div>
      {/* course */}
      <Course />
      {/* course */}
    </div>
  );
}
