import React from "react";
import { BiLogoYoutube, BiLogoInstagram } from "react-icons/bi";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { FiFacebook } from "react-icons/fi";
import epd from "../../assets/epd.png";
import { Link } from "react-router-dom";

export default function FooterBottm() {
  return (
    <div>
      {" "}
      <div className="footer py-5 w-full text-white">
        <div className="lg:w-[1140px] lg:mx-auto md:w-full sm:w-full w-full lg:px-0 md:px-2 sm:px-2 px-2  lg:flex items-center justify-between text-white">
          <Link to="/">
            <div className="logo lg:block md:hidden hidden">
              <img src={epd} alt="epd" />
            </div>
          </Link>
          <div className="footer_left text-[#0000009d]">
            <p className="lg:text-start text-center lg:pb-0 pb-5 text-white">
              Copyright © 2023 – Engineering Project Development Design By
              Engineering It Solution Project
            </p>
          </div>
          <div>
            <ul className="lg:flex md:flex flex gap-5 cursor-pointer justify-center">
              <Link
                target="_blank"
                className=" media hover:bg-red-600 p-2 rounded-full ring-1 ring-white hover:ring-white duration-200"
                to="https://www.facebook.com/profile.php?id=100085731316519"
              >
                <FiFacebook className="mediaLogo" size="20px" color="white" />
              </Link>
              <Link
                target="_blank"
                className=" media hover:bg-red-600 p-2 rounded-full ring-1 ring-white hover:ring-white duration-200"
                to="https://wa.me/qr/OVH3VWHWFQT5I1"
              >
                <AiOutlineWhatsApp
                  className="mediaLogo"
                  size="20px"
                  color="white"
                />
              </Link>
              <Link
                target="_blank"
                className=" media hover:bg-red-600 p-2 rounded-full ring-1 ring-white hover:ring-white duration-200"
                to="https://www.youtube.com/channel/UCTTpfSDJlEsa6lDYgdHJCyA"
              >
                <BiLogoYoutube
                  className="mediaLogo"
                  size="20px"
                  color="white"
                />
              </Link>
              <Link
                to="#"
                className=" media hover:bg-red-600 p-2 rounded-full ring-1 ring-white hover:ring-white duration-200"
              >
                <BiLogoInstagram className="mediaLogo" size="20px" />
              </Link>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
