import React from "react";
import bkash from "../../assets/epd group/bkash.png";
import nogod from "../../assets/epd group/nogod.png";

export default function Payment() {
  return (
    <div className="">
      <div className="lg:w-[1140px] mx-auto lg:px-0 md:px-3 px-3 cursor-pointer py-10 border-t-2">
        <div className="">
          <h2 className="lg:text-3xl text-xl text-center font-semibold font-sans pb-2">
            যে সকল মার্চেন্ট নাম্বার এর মাধ্যমে আমরা পেমেন্ট গ্রহন করি
          </h2>
          <div className="flex items-center justify-center  gap-5">
            <div className="bkash p-5 shadow-md rounded-md">
              <img
                className="w-[200px] h-[100px] object-cover"
                src={bkash}
                alt="bkash"
              />
              <li className="text-lg font-sans text-center">০১৬০০০০২৪২২</li>
            </div>
            <div className="bkash p-5 shadow-md rounded-md">
              <img
                className="w-[200px] h-[100px] object-cover"
                src={nogod}
                alt="nogod"
              />
              <li className="text-lg font-sans text-center">০১৬০০০০২৪২২</li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
