import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Software from "../../Software/Software";
import { LiaHandPointRight } from "react-icons/lia";
import HowToWork from "../../HowToWork/HowToWork";
import EpdItService from "../../EpdItServices/EpdItService";
import { RotatingSquare } from "react-loader-spinner";
import Payment from "../../../Components/Footer/Payment";
import FooterHeader from "../../../Components/Footer/FooterHeader";

export default function SingleCourse() {
  const db = process.env.REACT_APP_API;
  const [studentData, setStudentData] = useState({});
  const {
    fee,
    name,
    picture,
    duration,
    curriculum,
    duscription,
    overview,
    software,
  } = studentData;
  console.log(software);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const getData = async () => {
    setLoading(true);
    const { data } = await axios.get(
      `https://epd-website-project-server.vercel.app/course/${id}`
    );
    setStudentData(data?.data);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [id]);

  return (
    <>
      {/* loading */}
      {loading ? (
        <div className="flex items-center justify-center space-x-2">
          <RotatingSquare
            height="100"
            width="100"
            color="#4fa94d"
            ariaLabel="rotating-square-loading"
            strokeWidth="4"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div>
          <div className="lg:w-[1140px] lg:mx-auto md:w-full sm:w-full w-full lg:px-0 md:px-2 sm:px-2 px-2 pt-10">
            <h2 className="lg:text-5xl md:text-5xl sm:text-3xl text-3xl font-semibold tracking-wide text-[#FF6700] capitalize text-center">
              {name}
            </h2>
            <div className="lg:flex md:flex gap-10 py-10">
              {/* left start */}
              <div className="right lg:w-1/2 ">
                <div className="lg:flex md:flex lg:justify-between md:justify-between lg:items-center md:items-center">
                  {/* duration */}
                  <div className="box bg-transparent shadow-[#FF6700] shadow p-5 rounded-md lg:mb-0 md:mb-0 sm:mb-3 mb-3 lg:text-start md:text-start sm:text-center text-center duration-300 hover:bg-[#FF6700] hover:text-white cursor-pointer">
                    <h2 className="lg:text-2xl font-semibold tracking-wide text-[#FF6700]  duration-300">
                      কোর্সের মেয়াদ
                    </h2>
                    <p className="text-lg tracking-wide">{duration}</p>
                  </div>
                  <div className="box bg-transparent shadow-[#FF6700] shadow p-5 rounded-md lg:mb-0 md:mb-0 sm:mb-3 mb-3 lg:text-start md:text-start sm:text-center text-center duration-300 hover:bg-[#FF6700] hover:text-white cursor-pointer">
                    <h2 className="lg:text-2xl font-semibold tracking-wide text-[#FF6700]  duration-300">
                      লেকচার
                    </h2>
                    <p>৪৮ টি</p>
                  </div>
                  <div className="box bg-transparent shadow-[#FF6700] shadow p-5 rounded-md lg:mb-0 md:mb-0 sm:mb-3 mb-3 lg:text-start md:text-start sm:text-center text-center duration-300 hover:bg-[#FF6700] hover:text-white cursor-pointer">
                    <h2 className="lg:text-2xl font-semibold tracking-wide text-[#FF6700]  duration-300">
                      প্রজেক্ট
                    </h2>
                    <p>৮+</p>
                  </div>
                  {/* duration */}
                </div>
                <p className="pt-5 text-justify tracking-wide text-[#645f5f] text-xl">
                  {duscription}
                </p>
                <div className="btn py-3">
                  <button className=" bg-[#FF6700] px-7 py-3 ml-2 mt-5 rounded-md duration-300 hover:bg-[#ad4b0a] hover:text-white text-xl">
                    ভর্তি
                  </button>
                  <button className=" bg-[#FF6700] px-7 py-3 ml-2 mt-5 rounded-md duration-300 hover:bg-[#ad4b0a] hover:text-white text-xl">
                    ফ্রি সেমিনার
                  </button>
                </div>
              </div>
              {/* left end */}
              {/* right start */}
              <div className="lg:w-1/2">
                <div>
                  <img
                    className="w-full object-cover rounded-md "
                    src={picture}
                    alt="course_photo"
                  />
                </div>
              </div>
              {/* right start */}
            </div>

            {/* course Over View */}
            <div className="lg:flex  gap-5">
              <div className="left lg:w-[70%]">
                <h2 className="lg:text-6xl md:text-5xl sm:text-3xl text-3xl tracking-wide font-semibold text-[#FF6700] underline">
                  কোর্স ওভারভিউ
                </h2>
                <p className="pt-5 text-justify tracking-wide text-[#645f5f]">
                  {overview}
                </p>
              </div>
              {/* online crouse */}
              <div className="right lg:w-[30%] lg:pt-0 md:pt-5 sm:pt-5 pt-5 ">
                <div className="  bg-transparent shadow-[#FF6700] shadow  rounded-md px-5 py-5">
                  <h2 className="text-2xl font-semibold tracking-wide">
                    ভর্তি চলছে!
                  </h2>
                  <p className="pt-5 text-justify tracking-wide text-[#645f5f] text-xl">
                    অফলাইন (সরাসরি ইনস্টিটিউট) বা অনলাইন (লাইভ ক্লাস)- যে কোন
                    ব্যাচে সুবিধামতো সময় বেছে নিয়ে ভর্তি হতে পারেন এখনই।
                  </p>

                  <div className="enroll bg-[#FF6700] py-10 px-10 mt-5 rounded-md cursor-pointer">
                    <p className="para text-white font-bold text-xl tracking-wide">
                      কোর্স ফি (অফলাইন)
                    </p>
                    <h2 className="para text-white font-bold text-xl">{fee}</h2>
                    <button className="btns bg-[#fff] py-2  px-6 mt-5 rounded-md">
                      Enroll Now
                    </button>
                  </div>
                  <div className="enroll bg-[#FF6700] py-10 px-10 mt-5 rounded-md cursor-pointer">
                    <p className="para text-white font-bold text-xl tracking-wide">
                      কোর্স ফি (অনলাইন)
                    </p>
                    <h2 className=" para text-white font-bold text-xl">
                      {fee} taka
                    </h2>
                    <button className="bg-[#fff] py-2  px-6 mt-5 rounded-md">
                      Enroll Now
                    </button>
                  </div>
                </div>
              </div>
              {/* online crouse */}
            </div>
            {/* course Over View */}
            {/* Course Curriculum */}
            <div className="shadow-md mt-5 lg:w-[70%] md:w-[100%] w-full py-10 px-5 rounded-md">
              <h2 className="pt-5 pb-5 lg:text-6xl md:text-5xl sm:text-3xl text-3xl tracking-wide font-semibold text-[#FF6700] underline">
                কোর্স কারিকুলাম
              </h2>
              <div className="  grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 w-full py-10">
                {curriculum?.map((single) => {
                  return (
                    <p className=" tracking-wide text-lg underline hover:text-[#FF6700] duration-300 cursor-pointer capitalize flex gap-2 items-center pb-2">
                      <LiaHandPointRight size="25px" />
                      {single}
                    </p>
                  );
                })}
              </div>
            </div>
            {/* Course Curriculum */}
            {/* software */}
            <div className=" pt-10">
              <Software software={software} />
            </div>
            {/* software */}
            {/* How To work */}
            <div className=" pt-10">
              <HowToWork />
            </div>
            {/* How To work */}
            {/* How To work */}
            <div className=" pt-10">
              <EpdItService />
            </div>
            {/* How To work */}
            {/* How To work */}
            <div className=" pt-10">
              <Payment />
            </div>
            {/* How To work */}
          </div>
          <div>
            <FooterHeader />
          </div>
        </div>
      )}
      {/* loading */}
    </>
  );
}
