import React, { useEffect, useState } from "react";
import { AiFillStar } from "react-icons/ai";
import axios from "axios";
import { Link } from "react-router-dom";
import { BallTriangle, RotatingSquare } from "react-loader-spinner";

export default function CourseDeuration() {
  const [durations, setDurations] = useState([]);
  const [loading, setLoading] = useState(true);

  const durationData = async () => {
    setLoading(true);
    const { data } = await axios.get(
      "https://epd-website-project-server.vercel.app/course"
    );

    setDurations(data.data);
    setLoading(false);
  };
  useEffect(() => {
    durationData();
  }, []);
  return (
    <>
      <div className="lg:w-[1140px] lg:mx-auto md:w-full sm:w-full w-full lg:px-0 md:px-2 sm:px-2 px-2">
        <h2 className="lg:text-5xl md:text-5xl sm:text-4xl text-4xl capitalize underline text-[#FF6700] font-semibold tracking-wider py-5 lg:px-0 md:px-2 sm:px-2 px-2 text-center">
          সকল কোর্সে ভর্তি চলছে
        </h2>
        <p className=" text-justify tracking-wide lg:px-0 md:px-2 sm:px-2 px-2 lg:pr-2 md:pr-2 sm:pr-2 pr-2 pt-5 text-xl font-thin leading-[34px] text-[#575757] font-sans">
          দেশ ও দেশের বাইরে বর্তমানে যে স্কিলগুলোর চাহিদা সবচেয়ে বেশি, সেগুলো
          অন্তর্ভুক্ত করেই সাজানো হয়েছে আমাদের কোর্সের তালিকা। এখান থেকে আপনার
          সুবিধামত অনলাইন বা অফলাইনে কোর্সে এনরোল করতে পারবেন যেকোনো সময়।
        </p>
        {/* duration List */}
        {loading ? (
          <div className="flex items-center justify-center space-x-2">
            <BallTriangle
              height={100}
              width={100}
              radius={5}
              color="#4fa94d"
              ariaLabel="ball-triangle-loading"
              wrapperClass={{}}
              wrapperStyle=""
              visible={true}
            />
          </div>
        ) : (
          <div className="wrapper grid lg:grid-cols-3 md:grid-cols-3 lg:px-0 md:px-2 sm:px-2 px-2 gap-10 py-10 ">
            {durations?.map(({ _id, name, fee, ratting, picture }) => {
              return (
                <div
                  key={_id}
                  className="shadow-md shadow-[#FF6700] pb-5 rounded-md  relative"
                >
                  <div className="pic overflow-hidden pb-5  border-spacing-2 border-red-500  h-[40vh] w-full">
                    <img
                      className=" object-cover block hover:scale-110 duration-300 cursor-pointer border-red-500 h-[40vh] w-full"
                      src={picture}
                      alt="graphics"
                    />
                  </div>
                  <h2 className="pt-5 pl-2 text-xl  tracking-wider pb-3  h-[133px]">
                    {name}
                  </h2>
                  <div className="rating_list flex items-center pt-1 pl-2 text-xl font-semibold tracking-wider">
                    {[...Array(parseInt(ratting))].map((single) => (
                      <AiFillStar className="text-[#FF6700]" />
                    ))}
                  </div>
                  <div className="course_fee ">
                    <p className="pt-1 pl-2 text-xl font-bold pb-3 tracking-wider">
                      কোর্স ফি: {fee}
                    </p>
                  </div>
                  <div className="button ">
                    <Link to={`/singleCourse/${_id}`}>
                      <button className=" bg-[#FF6700] px-7 py-2 ml-2 mt-2 rounded-md text-xl ">
                        বিস্তারিত
                      </button>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {/* duration List */}
      </div>
    </>
  );
}
