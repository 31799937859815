import About from "../Pages/About/About";
import Course from "../Pages/Course/Course";
import CourseDeuration from "../Pages/Course/CourseDeuration";
import SingleCourse from "../Pages/Course/SingleCourese/SingleCourse";
import EpdFamily from "../Pages/EpdFamily/EpdFamily";
import EpdIndustrial from "../Pages/EpdIndustrial/EpdIndustrial";
import EpdItServices from "../Pages/EpdItServices/EpdItServices";
import EpdProjectDevelopMent from "../Pages/EpdProjectDevelopMent/EpdProjectDevelopMent";
import ErrorElement from "../Pages/ErrorElement/ErrorElement";
import Freelancing from "../Pages/Freelancing/Freelancing";
import Home from "../Pages/Home/Home";
import Privacy from "../Pages/Privacy/Privacy";
import Registration from "../Pages/Registration/Registration";
import StudentInfo from "../Pages/StudentInfo/StudentInfo";

const {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} = require("react-router-dom");
const { default: Layout } = require("../RootLayout/Layout");

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorElement />}>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/family" element={<EpdFamily />} />
        <Route path="/course" element={<Course />} />
        <Route path="/freelancing" element={<Freelancing />} />
        <Route path="/itServices" element={<EpdItServices />} />
        <Route path="/epdIndustrial" element={<EpdIndustrial />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/studentInfo" element={<StudentInfo />} />
        <Route path="/projectDevelopment" element={<EpdProjectDevelopMent />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/courses" element={<CourseDeuration />} />
        <Route path="/singleCourse/:id" element={<SingleCourse />} />
      </Route>
    </Route>
  )
);
