import React from "react";

export default function StudentInfo() {
  return (
    <div className="lg:w-[1140px] lg:mx-auto md:w-full sm:w-full w-full py-20 lg:px-0 md:px-2 sm:px-2 px-2">
      <div className="grid lg:grid-cols-2 md:grid-cols-2 ">
        <div className="right_site_student  p-10 shadow shadow-[#FF6700]">
          <h2 className="text-5xl font-semibold tracking-wide text-[#FF6700]">
            Student Info
          </h2>
          <div className="">
            <input
              className="border-b-2 outline-none border-[#FF6700] w-full px-5 py-2 bg-transparent mt-5 required block"
              type="number"
              name="number"
              id="number"
              placeholder="Type Your Id Number"
            />
            <div className="text-end mt-5">
              <button className="py-2 px-8 bg-[#FF6700] rounded-md">
                Submit
              </button>
            </div>
          </div>
        </div>
        {/* left site */}
        {/* <div className="left_stie p-10 shadow">
          <h2 className="text-center lg:text-5xl md:text-4xl text-3xl tracking-wide text-[#FF6700] font-semibold">
            Student Details
          </h2>
          <h3 className="pt-5 text-xl tracking-wide">Name : Ashikur</h3>
          <h3 className="pt-1 text-xl tracking-wide">Roll:45356</h3>
          <h3 className="pt-1 text-xl tracking-wide">
            Student Id : 4576567657
          </h3>
        </div> */}
        {/* left site */}
      </div>
    </div>
  );
}
