import React from "react";
import freelancing from "../../assets/how to work/freelancing.png";
import jobemploy from "../../assets/how to work/jobempoly.png";
import student from "../../assets/how to work/students.png";
import housewife from "../../assets/how to work/housewifes.png";
import uddokta from "../../assets/how to work/uddokta.png";
import probasi from "../../assets/how to work/propasi.png";
import img1 from "../../assets/marketPlace/1.png";
import img2 from "../../assets/marketPlace/2.png";
import img3 from "../../assets/marketPlace/3.png";
import img4 from "../../assets/marketPlace/4.png";
import img5 from "../../assets/marketPlace/5.png";
import img6 from "../../assets/marketPlace/6.png";
import img7 from "../../assets/marketPlace/7.png";
import img8 from "../../assets/marketPlace/8.png";
import img9 from "../../assets/marketPlace/9.png";
import img10 from "../../assets/marketPlace/10.png";
import img11 from "../../assets/marketPlace/11.png";
import img12 from "../../assets/marketPlace/12.png";
import img13 from "../../assets/marketPlace/13.png";
import img14 from "../../assets/marketPlace/14.png";
import img15 from "../../assets/marketPlace/15.png";
import Payment from "../../Components/Footer/Payment";
import FooterHeader from "../../Components/Footer/FooterHeader";

export default function Freelancing() {
  return (
    <div>
      <div className="lg:w-[1140px] lg:mx-auto md:w-full sm:w-full w-full py-10 lg:px-0 md:px-2 sm:px-2 px-2">
        <div>
          <h2 className="pt-5 pb-5 lg:text-6xl md:text-5xl sm:text-3xl text-3xl tracking-wide font-semibold text-[#FF6700]">
            ফ্রিল্যান্সিং
          </h2>
          <div className="freelancing lg:flex md:flex lg:gap-5 md:gap-5 py-10 rounded-3xl px-10">
            <div className="description lg:w-[50%] md:w-[50%] w-full">
              <h2 className="lg:text-3xl md:text-3xl text-3xl font-semibold text-[#fff] pb-5">
                ফ্রিল্যান্সিং
              </h2>
              <p className=" text-justify text-[#eeeaeadf] font-[var(--bangla-font)] text-lg">
                বাংলাদেশে আইসিটি ডিভিশনের এক জরিপ থেকে দেখা গেছে, বাংলাদেশে
                প্রায় ৫ লাখেরও বেশি ফ্রিল্যান্সার বিভিন্ন সেক্টরে কাজ করছেন।
                স্বাধীনভাবে কাজ করে স্বাবলম্বী হতে ট্রেন্ডি পেশা হিসেবে সবাই
                ফ্রিল্যান্সিং-কে বেছে নিচ্ছে। এভাবেই বিশ্বজুড়ে প্রায় ১ বিলিয়নেরও
                বেশি মানুষ ফ্রিল্যান্সিং করছেন। বেশিরভাগ ফ্রিল্যান্সারের গড়
                মাসিক আয় প্রায় ৪০ হাজারেরও বেশি।
              </p>
            </div>
            <div className="market_place  lg:w-[50%] md:w-[50%] w-full">
              <h2 className="lg:text-3xl md:text-3xl text-3xl font-semibold text-[#fff] pb-2 lg:pt-0 md:pt-0 pt-5 text-center">
                ফ্রিল্যান্স মার্কেটপ্লেস
              </h2>
              <p className=" text-justify text-[#eeeaeadf] font-[var(--bangla-font)] text-lg lg:px-8 md:px-8 lg:pt-0 md:pt-0 pt-1">
                ফ্রিল্যান্স কাজের জন্য রয়েছে বিভিন্ন মার্কেটপ্লেস। আন্তর্জাতিক
                এসব মার্কেটপ্লেসের প্রত্যেকটি ভিন্ন ভিন্ন নিয়ম মেনে চললেও কাজের
                সুবিধা রয়েছে সবখানেই।
              </p>
              <div className="grid lg:grid-cols-3 md:grid-col-3 grid-cols-2 gap-5 pt-5">
                <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
                  <img className=" w-[80%] rounded-md " src={img1} alt="img1" />
                </div>
                <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
                  <img src={img2} alt="img2" />
                </div>
                <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
                  <img src={img3} alt="img3" />
                </div>
                <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
                  <img src={img4} alt="img4" />
                </div>
                <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
                  <img src={img5} alt="img5" />
                </div>
                <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
                  <img src={img6} alt="img6" />
                </div>
                <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
                  <img src={img7} alt="img7" />
                </div>
                <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
                  <img src={img8} alt="img8" />
                </div>
                <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
                  <img src={img9} alt="img9" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* how to work */}
        <div className="pt-10">
          <h2 className="pt-5 pb-5 lg:text-6xl md:text-5xl sm:text-3xl text-3xl tracking-wide font-semibold text-[#FF6700]  ">
            ফ্রিল্যান্সিং যাদের জন্য
          </h2>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 gap-5">
            {/* single software */}
            <div className="flex flex-col gap-3 items-center shadow-md p-5 rounded-md">
              <img src={freelancing} alt="freelancing" />
              <li className=" text-2xl font-extrabold tracking-wide text-[#292828c3]">
                ফ্রিল্যান্সিং এ আগ্রহী
              </li>
            </div>
            {/* single software */}
            <div className="flex flex-col gap-3 items-center shadow-md p-5 rounded-md flex-wrap">
              <img src={jobemploy} alt="jobempoly" />
              <li className=" text-2xl font-extrabold tracking-wide text-[#292828c3]">
                চাকুরী প্রত্যাশী
              </li>
            </div>
            <div className="flex flex-col gap-3 items-center shadow-md p-5 rounded-md">
              <img src={student} alt="student" />
              <li className=" text-2xl font-extrabold tracking-wide text-[#292828c3]">
                ছাত্র-ছাত্রী
              </li>
            </div>
            <div className="flex flex-col gap-3 items-center shadow-md p-5 rounded-md">
              <img src={housewife} alt="housewife" />
              <li className=" text-2xl font-extrabold tracking-wide text-[#292828c3]">
                গৃহিণী
              </li>
            </div>
            <div className="flex flex-col gap-3 items-center shadow-md p-5 rounded-md">
              <img src={uddokta} alt="housewife" />
              <li className=" text-2xl font-extrabold tracking-wide text-[#292828c3]">
                উদ্যোক্তা
              </li>
            </div>
            <div className="flex flex-col gap-3 items-center shadow-md p-5 rounded-md">
              <img src={probasi} alt="housewife" />
              <li className=" text-2xl font-extrabold tracking-wide text-[#292828c3]">
                প্রবাসী
              </li>
            </div>
          </div>
        </div>
        {/* how to work */}
        {/* how to work filed */}
        <div className="pt-10">
          <h2 className="pt-5 pb-5 lg:text-6xl md:text-5xl sm:text-3xl text-3xl tracking-wide font-semibold text-[#FF6700]">
            আপনি যেখানে কাজ করতে পারেন
          </h2>
          <p className=" text-justify text-[#3b3939df] font-[var(--bangla-font)] text-lg lg:pr-48 md:pr-32">
            বিভিন্ন লোকাল বা রিমোট জবের পাশাপাশি আপনি ফ্রিল্যান্সিং মার্কেটপ্লেস
            বেছে নিতে পারেন কাজের জন্য। স্মার্ট স্যালারি আর স্বচ্ছন্দে কাজের
            সুযোগ থাকায় যেকোনো এক বা একাধিক মার্কেটপ্লেসের অংশ হতে পারেন সহজেই।
          </p>
          {/* place */}
          <div className="grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-4 grid-cols-1 gap-5 pt-10">
            <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
              <img className="  rounded-md " src={img1} alt="img1" />
            </div>
            <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
              <img src={img2} alt="img2" />
            </div>
            <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
              <img src={img3} alt="img3" />
            </div>
            <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
              <img src={img4} alt="img4" />
            </div>
            <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
              <img src={img5} alt="img5" />
            </div>
            <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
              <img src={img6} alt="img6" />
            </div>
            <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
              <img src={img7} alt="img7" />
            </div>
            <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
              <img src={img8} alt="img8" />
            </div>
            <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
              <img src={img9} alt="img9" />
            </div>
            <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
              <img src={img10} alt="img9" />
            </div>
            <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
              <img src={img11} alt="img9" />
            </div>
            <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
              <img src={img12} alt="img9" />
            </div>
            <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
              <img src={img13} alt="img9" />
            </div>
            <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
              <img src={img14} alt="img9" />
            </div>
            <div className="freelancing-img bg-white py-3 flex justify-center items-center rounded-lg">
              <img src={img15} alt="img9" />
            </div>
          </div>
        </div>
        {/* how to work filed */}
        {/* prement */}
        <div className="pt-10">
          <Payment />
        </div>
      </div>
      {/* footer */}
      <div className="pt-10">
        <FooterHeader />
      </div>
    </div>
  );
}
