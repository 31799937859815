import React from "react";
import img from "../../assets/software/atom.png";

export default function Software({ software }) {
  return (
    <>
      <div>
        <h2 className="pt-5 pb-5 lg:text-6xl md:text-5xl sm:text-3xl text-3xl tracking-wide font-semibold text-[#FF6700] underline text-center">
          যেসব সফটওয়্যার শেখানো হয়
        </h2>

        <div className="software">
          <div className=" lg:flex md:flex gap-5 justify-center flex-wrap py-10">
            {/* single software */}
            {software.map((soft) => (
              <div className="flex gap-3 items-center shadow-md p-5 rounded-md">
                <img
                  className=" w-[120px] object-cover"
                  src={soft.img}
                  alt="atom"
                />{" "}
                <li className=" text-2xl font-extrabold tracking-wide text-[#292828c3]">
                  {soft?.name && soft.name}
                </li>
              </div>
            ))}

            {/* single software */}
          </div>
        </div>
      </div>
    </>
  );
}
