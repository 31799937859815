import React from "react";
import epdgroup from "../../assets/epd group/epd group.png";
import { Link } from "react-router-dom";

export default function Epdgroup() {
  return (
    <div>
      {/* epd group */}
      <div className="epd_wrapper py-5 lg:flex md:flex">
        {/* left */}
        <div className="epd_left lg:w-[30%] lg:pr-20 md:px-10 lg:px-0  sm:px-2 px-2 pb-10 md:w-[30%]">
          <Link to="https://www.facebook.com/groups/259895511341599">
            <img src={epdgroup} alt="epd-group" />
          </Link>
        </div>
        {/* right */}
        <div className="epd_right flex-1">
          <Link to="https://www.facebook.com/groups/259895511341599">
            <h2 className="text-5xl text-[#FF6700] underline font-semibold tracking-wide pb-5 lg:px-0 md:px-0 sm:px-2 px-2">
              EPD GROUP
            </h2>
          </Link>
          <p className=" text-justify tracking-wide lg:px-0 md:px-0 sm:px-2 px-2 lg:pr-2 md:pr-2 sm:pr-2 pr-2">
            The EPD Group has started operation as a Engineering consultancy &
            DEVELOPMENT under the guidance of the group’s first concern –
            <span className="text-xl text-[#FF6700]">
              Engineering project Developments Ltd in 2016
            </span>
            . The business turned out to be a very successful one and had helped
            foster the growth of faith and confidence of the urban people of
            bangladesh
          </p>
          <p className=" text-justify tracking-wide lg:px-0 md:px-0 sm:px-2 px-2 lg:pr-2 md:pr-2 sm:pr-2 pr-2">
            Driven by the success, EPD group geared up to invest in many new
            fields. Including one EPD Design and Interior we ensure a
            high-quality architectural design through bunch of high qualified
            Architect and pleasing to the eye decorative ideas for clients .
          </p>
          <div className=" lg:px-0 md:px-0 sm:px-2 px-2">
            <li className="flex items-center gap-3 pt-2">
              <span className="w-[20px] h-[20px] rounded-full bg-[#FF6700] inline-block"></span>
              <Link to="/projectDevelopment">
                <p className=" font-medium text-lg">
                  EGNINEERING PROJECT DEVELOPMENTS LTD
                </p>
              </Link>
            </li>
            <Link to="/itServices">
              <li className="flex items-center gap-3 pt-2">
                <span className="w-[20px] h-[20px] rounded-full bg-[#FF6700] inline-block"></span>
                <p className=" font-medium text-lg">EPD IT SOLUTION</p>
              </li>
            </Link>
            <Link to="/epdIndustrial">
              <li className="flex items-center gap-3 pt-2">
                <span className="w-[20px] h-[20px] rounded-full bg-[#FF6700] inline-block"></span>
                <p className=" font-medium text-lg">
                  EPD INDUSTRIAL AUTOMATION & ENGINEERING
                </p>
              </li>
            </Link>
          </div>
          <div className=" w-[95%] mx-auto h-[2px] bg-[#FF6700] mt-10 lg:px-0 md:px-0 sm:px-2 px-2"></div>
          {/* Experience */}
          <div className="flex gap-16 pt-10">
            <div className="flex items-center ">
              <h1 className=" lg:text-9xl md:text-9xl sm:text-5xl text-5xl pr-5 font-semibold text-[#FF6700] lg:px-0 md:px-0 sm:px-2 px-2">
                7
              </h1>
              <p className="lg:text-3xl md:text-3xl sm:text-lg text-lg font-bold text-[#FF6700]">
                Years of Experience
              </p>
            </div>
            <div className="flex items-center">
              <h2 className=" lg:text-9xl md:text-9xl sm:text-5xl text-5xl pr-5 font-semibold text-[#FF6700] lg:px-0 md:px-0 sm:px-2 px-2">
                32
              </h2>
              <p className="lg:text-3xl md:text-3xl sm:text-xl text-xl font-bold text-[#FF6700]">
                Awards & Honors
              </p>
            </div>
          </div>
          {/* Experience */}
        </div>
        {/* right */}
      </div>
      {/* epd group */}
    </div>
  );
}
