import React from "react";
import founder from "../../assets/family/founder.jpg";
import manegment from "../../assets/family/manegment.jpg";
import instructor from "../../assets/family/tahomina.jpg";
import mern from "../../assets/family/mern.jpg";
import blank from "../../assets/family/blank.png";

export default function EpdFamily() {
  return (
    <div className=" lg:w-[1140px] lg:mx-auto md:w-full sm:w-full w-full py-20 lg:px-0 md:px-2 sm:px-2 px-2">
      <h2 className=" lg:text-5xl md:text-5xl sm:text-3xl text-3xl text-[#FF6700] font-semibold text-center">
        Team - Epd Group
      </h2>
      <p className=" text-lg tracking-wide text-center pt-5 ">
        We started with one mission: Make Learning fun. Since then, all five and
        a half caffeine sucking night owls are having fun.
      </p>
      {/* team section*/}
      <div className="grid lg:grid-cols-3 md:grid-cols-3 gap-4 pt-20">
        {/* parson */}
        <div className="shadow shadow-[#FF6700] p-5  ">
          <img
            className="object-cover w-full overflow-hidden h-[80vh] rounded-md"
            src={founder}
            alt=""
          />
          <h2 className="pt-2 font-semibold">MD. LIGING HOSSAIN</h2>
          <p className="pt-1 text-[#71706f]">Founder & Chairman</p>
        </div>
        {/* parson */}
        {/* parson 1 */}
        <div className=" rounded-md  w-[100%] shadow shadow-[#FF6700] p-5">
          <img
            className="object-cover w-full overflow-hidden h-[80vh] rounded-md"
            src={manegment}
            alt=""
          />
          <h2 className="pt-2 font-semibold">AL-IMRAN</h2>
          <p className="pt-1 text-[#71706f]">Managing Director</p>
        </div>
        {/* parson 1 */}
        {/* parson */}
        <div className=" rounded-md  w-[100%] shadow shadow-[#FF6700] p-5">
          <img
            className="object-cover w-full overflow-hidden h-[80vh] rounded-md"
            src={instructor}
            alt=""
          />
          <h2 className="pt-2 font-semibold">TAHMINA AKTER</h2>
          <p className="pt-1 text-[#71706f]"> Chief Executive Officer</p>
        </div>
        {/* parson */}
        {/* parson */}
        <div className=" rounded-md  w-[100%] shadow shadow-[#FF6700] p-5">
          <img
            className="object-cover w-full overflow-hidden h-[80vh] rounded-md"
            src={blank}
            alt=""
          />
          <h2 className="pt-2 font-semibold">Md Saruar Hosen</h2>
          <p className="pt-1 text-[#71706f]"> Instructor</p>
        </div>
        {/* parson */}
        {/* parson */}
        <div className=" rounded-md  w-[100%] shadow shadow-[#FF6700] p-5">
          <img
            className="object-cover w-full overflow-hidden h-[80vh] rounded-md"
            src={blank}
            alt=""
          />
          <h2 className="pt-2 font-semibold">Sk Badsha Ali</h2>
          <p className="pt-1 text-[#71706f]"> Instructor</p>
        </div>
        {/* parson */}
        {/* parson */}
        <div className=" rounded-md  w-[100%] shadow shadow-[#FF6700] p-5">
          <img
            className="object-cover w-full overflow-hidden h-[80vh] rounded-md"
            src={blank}
            alt=""
          />
          <h2 className="pt-2 font-semibold">Md Rayhan Kabir</h2>
          <p className="pt-1 text-[#71706f]"> Instructor</p>
        </div>
        {/* parson */}
        {/* parson */}
        <div className=" rounded-md  w-[100%] shadow shadow-[#FF6700] p-5">
          <img
            className="object-cover w-full overflow-hidden h-[80vh] rounded-md"
            src={mern}
            alt=""
          />
          <h2 className="pt-2 font-semibold">MD Sazzad Hossain</h2>
          <p className="pt-1 text-[#71706f]"> Mern Stack Developer</p>
        </div>
        {/* parson */}

        {/* parson */}
        <div className=" rounded-md  w-[100%] shadow shadow-[#FF6700] p-5">
          <img
            className="object-cover w-full overflow-hidden h-[80vh] rounded-md"
            src={blank}
            alt=""
          />
          <h2 className="pt-2 font-semibold">Rabby Nur Islam</h2>
          <p className="pt-1 text-[#71706f]"> Graphic Designer</p>
        </div>
        {/* parson */}
        {/* parson */}
        <div className=" rounded-md  w-[100%] shadow shadow-[#FF6700] p-5">
          <img
            className="object-cover w-full overflow-hidden h-[80vh] rounded-md"
            src={blank}
            alt=""
          />
          <h2 className="pt-2 font-semibold">Engineer Mazharul Islam</h2>
          <p className="pt-1 text-[#71706f]">Senior Programmer</p>
        </div>
        {/* parson */}
      </div>
      {/* team section*/}
    </div>
  );
}
