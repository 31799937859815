import React from "react";
import { FcReading, FcLike, FcGallery, FcManager } from "react-icons/fc";

export default function EpdItService() {
  return (
    <div>
      {/* services */}
      <div className="lg:w-[1140px] lg:mx-auto md:w-full sm:w-full w-full py-20">
        <h2 className="text-3xl font-semibold capitalize tracking-wide text-start text-[#FF6700] lg:px-0 md:px-2 sm:px-2 px-2 underline">
          ইপিডি গ্রুপ এর বিশেষ সেবা
        </h2>
        <div className="wrapper grid lg:grid-cols-2 md:grid-cols-2 lg:px-0 md:px-2 sm:px-2 px-2 gap-10 py-10">
          {/* services */}
          <div className="services shadow-md shadow-[#FF6700] p-5 rounded-md">
            <div className="logo">
              <FcReading className="text-7xl" />
            </div>
            <div className="name">
              <h2 className="py-5 text-4xl font-semibold tracking-wider">
                Review Class
              </h2>
            </div>
            <div className="duration">
              <p className=" text-justify tracking-wide lg:px-0 md:px-0 sm:px-2 px-2 lg:pr-2 md:pr-2 sm:pr-2 pr-2 text-xl">
                আমাদের রিভিউ ক্লাসের ব্যবস্থা আছে যাতে শিক্ষার্থীরা কোর্স
                চলাকালীন কোনো বিষয় বা ক্লাস বুঝতে কোনো অসুবিধা না হয়।
                পর্যালোচনা ক্লাসের উদ্দেশ্য হল প্রত্যেক শিক্ষার্থী ক্লাস থেকে
                100% শিখছে তা নিশ্চিত করা। ব্যস্ততার মধ্যেও সৃজনশীল আইটিতে
                দক্ষতার বিকাশ এখন পুরোদমে হবে।
              </p>
            </div>
          </div>
          {/* services */}
          {/* services */}
          <div className="services shadow-md shadow-[#FF6700] p-5 rounded-md">
            <div className="logo">
              <FcLike className="text-7xl" />
            </div>
            <div className="name">
              <h2 className="py-5 text-4xl font-semibold tracking-wider">
                Lifetime Support
              </h2>
            </div>
            <div className="duration">
              <p className=" text-justify tracking-wide lg:px-0 md:px-0 sm:px-2 px-2 lg:pr-2 md:pr-2 sm:pr-2 pr-2 text-xl">
                প্রশিক্ষণ শেষ হলেও আপনার সাথে সম্পর্ক এখানেই শেষ নয়। একজন ইপিডি
                আইটি এর হিসেবে আপনি লাইফ টাইম সাপোর্ট পাবেন। যেকোনো সময় 24/7
                অনলাইন সমর্থন। আমাদের বিষয়ভিত্তিক অভিজ্ঞ দল অফলাইন বা অনলাইনে
                এই সহায়তা নিশ্চিত করে।
              </p>
            </div>
          </div>
          {/* services */}
          {/* services */}
          <div className="services shadow-md shadow-[#FF6700] p-5 rounded-md">
            <div className="logo">
              <FcGallery className="text-7xl" />
            </div>
            <div className="name">
              <h2 className="py-5 text-4xl font-semibold tracking-wider">
                Practice Lab Support
              </h2>
            </div>
            <div className="duration">
              <p className=" text-justify tracking-wide lg:px-0 md:px-0 sm:px-2 px-2 lg:pr-2 md:pr-2 sm:pr-2 pr-2 text-xl">
                কোর্স চলাকালীন সময়ে কোনভাবেই যেন শিক্ষার্থীদের নিয়মিত
                অনুশীলনের ব্যঘাত না হয়, সেজন্যে আমাদের রয়েছে আলাদা ল্যাব
                সুবিধা। ইপিডি আইটির শিক্ষার্থী হিসেবে এই ল্যাবে যেকোনো সময়,
                যতক্ষণ ইচ্ছা আপনি আপনার কোর্স বিষয়ক কাজের চর্চা করতে পারবেন।
              </p>
            </div>
          </div>
          {/* services */}
          {/* services */}
          <div className="services shadow-md shadow-[#FF6700] p-5 rounded-md">
            <div className="logo">
              <FcManager className="text-7xl" />
            </div>
            <div className="name">
              <h2 className="py-5 text-4xl font-semibold tracking-wider">
                Career Placement Support
              </h2>
            </div>
            <div className="duration">
              <p className=" text-justify tracking-wide lg:px-0 md:px-0 sm:px-2 px-2 lg:pr-2 md:pr-2 sm:pr-2 pr-2 text-xl">
                শিক্ষার্থীদের যোগ্যতা অনুযায়ী সঠিক জায়গায় সিভি পৌছাতে কাজ করে
                থাকে ক্যারিয়ার প্লেসমেন্ট ডিপার্টমেন্ট। এখান থেকে আপনি পাবেন
                কোর্স পরবর্তী গ্রুমিং এবং ক্যারিয়ার গাইডলাইন বিষয়ক বিভিন্ন
                সেমিনার। যা ক্যারিয়ার দৌড়ে অন্য যে কারও থেকে আপনাকে এগিয়ে
                রাখবে অনেকখানি।
              </p>
            </div>
          </div>
          {/* services */}
        </div>
      </div>
      {/* close services */}
    </div>
  );
}
