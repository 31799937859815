import React from "react";
import { GoLocation } from "react-icons/go";
import { BiPhoneCall } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function ContactInfo() {
  return (
    <div className="py-10">
      <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 grid-cols-1 gap-5 lg:px-0 md:px-2 sm:px-2 px-2 ">
        {/* address */}
        <div className=" rounded-md flex flex-col justify-center items-center py-5 shadow-md shadow-[#FF6700]">
          <div className="logo ">
            <GoLocation className="text-5xl text-[#FF6700] " />
          </div>
          <h1 className=" capitalize text-2xl tracking-wider font-semibold  text-[#FF6700] pt-2 pb-3">
            Location
          </h1>
          <h2 className="capitalize text-2xl text-[#FF6700] font-semibold">
            Main Branch:
          </h2>
          <li className=" tracking-widest text-[#FF6700]  text-center font-sans pb-3 ">
            Aftab Uddin Bhuiya road, West vhuruliya, Gazipur -1707
          </li>
          <h1 className="capitalize text-2xl text-[#FF6700] font-semibold">
            Faridpur Branch:
          </h1>
          <li className=" tracking-widest text-[#FF6700]  text-center font-sans">
            Puratan Passport Office Mor Jhiltuli, Faridpur
          </li>
        </div>
        {/* address */}
        {/* Phone */}
        <div className=" rounded-md flex flex-col justify-center items-center py-5 shadow-md shadow-[#FF6700]">
          <div className="logo ">
            <GoLocation className="text-5xl text-[#FF6700] " />
          </div>
          <h1 className=" capitalize text-2xl tracking-wider font-semibold  text-[#FF6700] pt-2 pb-3">
            Phone
          </h1>
          <h2 className="capitalize text-2xl text-[#FF6700] font-semibold">
            Main Branch:
          </h2>
          <div>
            <li className=" tracking-widest text-[#FF6700]  text-center font-sans  ">
              01600002422
            </li>
            <li className=" tracking-widest text-[#FF6700]  font-sans ">
              01862308272
            </li>
          </div>
          <h1 className="capitalize text-2xl text-[#FF6700] font-semibold pt-2">
            Faridpur Branch:
          </h1>
          <li className=" tracking-widest text-[#FF6700]  font-sans ">
            01734129265
          </li>
        </div>

        {/* Phone */}
        {/* Email */}
        <div className=" rounded-md flex flex-col justify-center items-center py-5 shadow-md shadow-[#FF6700]">
          <div className="logo ">
            <AiOutlineMail className="text-5xl text-[#FF6700] " />
          </div>
          <h1 className=" capitalize text-2xl tracking-wider font-semibold  text-[#FF6700] pt-2">
            Email
          </h1>
          <Link>
            <li className=" tracking-widest text-[#FF6700] pt-2 font-sans font-semibold">
              epdltsolution@gmail.com
            </li>
          </Link>
          <Link>
            <li className=" tracking-widest text-[#FF6700] pt-2 font-sans font-semibold">
              epd2k16ltd@gmail.com
            </li>
          </Link>
        </div>
        {/* Email */}
      </div>
    </div>
  );
}
