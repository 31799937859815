import React from "react";
import { LiaHandPointRight } from "react-icons/lia";
import { Link } from "react-router-dom";
import FooterHeader from "../../Components/Footer/FooterHeader";

export default function EpdIndustrial() {
  return (
    <div>
      <div className="lg:w-[1140px] lg:mx-auto md:w-full sm:w-full w-full py-20 lg:px-0 md:px-2 sm:px-2 px-2">
        <h2 className="text-center tracking-wide lg:text-5xl md:text-4xl sm:text-3xl text-3xl text-[#FF6700] font-semibold pb-10">
          Epd Industrial Automotion & Engineering
        </h2>

        <div className=" shadow-md shadow-[#FF6700] py-10 px-5 rounded-md mt-5">
          <h2 className="text-5xl  text-[#FF6700] underline pb-10">
            𝕆𝕦𝕣 𝕊𝕖𝕣𝕧𝕚𝕔𝕖
          </h2>
          <li className=" flex gap-1 pb-2">
            <LiaHandPointRight size="25px" color="#FF6700" />
            <p className="tracking-wide text-lg ">
              Automation System Manufactureing,Saleing & Servicing
            </p>
          </li>
          <li className=" flex gap-1 pb-2">
            <LiaHandPointRight size="25px" color="#FF6700" />
            <p className="tracking-wide text-lg ">
              Refrigerator & Air-Conditionar Servicing
            </p>
          </li>
          <li className=" flex gap-1 pb-2">
            <LiaHandPointRight size="25px" color="#FF6700" />
            <p className="tracking-wide text-lg "> MEP Design</p>
          </li>
        </div>

        <div className=" shadow-md shadow-[#FF6700] py-10 px-5 rounded-md mt-5">
          <h2 className="text-5xl pb-10 text-[#FF6700] underline">
            Professional Course
          </h2>
          <Link to="/courses">
            <li className=" hover:text-[#FF6700] duration-300 flex gap-1 pb-2">
              <LiaHandPointRight size="25px" color="#FF6700" />
              <p className=" tracking-wide text-lg underline">
                Industrial Automation-PIC,VFD,HMI
              </p>
            </li>
          </Link>
          <Link to="/courses">
            <li className=" hover:text-[#FF6700] duration-300 flex gap-1 pb-2">
              <LiaHandPointRight size="25px" color="#FF6700" />
              <p className=" tracking-wide text-lg underline">
                {" "}
                Micro Controller & Robotics
              </p>
            </li>
          </Link>
          <Link to="/courses">
            <li className=" hover:text-[#FF6700] duration-300 flex gap-1 pb-2">
              <LiaHandPointRight size="25px" color="#FF6700" />
              <p className=" tracking-wide text-lg underline">
                {" "}
                Solid Work With Full Project
              </p>
            </li>
          </Link>
          <Link to="/courses">
            <li className=" hover:text-[#FF6700] duration-300 flex gap-1 pb-2">
              <LiaHandPointRight size="25px" color="#FF6700" />
              <p className=" tracking-wide text-lg underline">
                {" "}
                Mechanical Auto Cad 2D & 3D
              </p>
            </li>
          </Link>
          <Link to="/courses">
            <li className=" hover:text-[#FF6700] duration-300 flex gap-1 pb-2">
              <LiaHandPointRight size="25px" color="#FF6700" />
              <p className=" tracking-wide text-lg underline">
                Professional Electrical Design
              </p>
            </li>
          </Link>
          <Link to="/courses">
            <li className=" hover:text-[#FF6700] duration-300 flex gap-1 pb-2">
              <LiaHandPointRight size="25px" color="#FF6700" />
              <p className=" tracking-wide text-lg underline">
                {" "}
                Professional MEP Design
              </p>
            </li>
          </Link>
          <Link to="/courses">
            <li className=" hover:text-[#FF6700] duration-300 flex gap-1 pb-2">
              <LiaHandPointRight size="25px" color="#FF6700" />
              <p className=" tracking-wide text-lg underline">
                {" "}
                Automobile & CNG Conversation
              </p>
            </li>
          </Link>
        </div>

        <div className=" shadow-md shadow-[#FF6700] py-10 px-5 rounded-md mt-5">
          <h2 className="text-5xl pb-10 text-[#FF6700] underline">
            Our Facilities
          </h2>
          <li className=" tracking-wide text-lg flex items-center gap-1 pb-2">
            <LiaHandPointRight size="25px" color="#FF6700" />
            Industrial Experienced Trainer
          </li>
          <li className=" tracking-wide text-lg flex items-center gap-1 pb-2">
            <LiaHandPointRight size="25px" color="#FF6700" />
            Unlimited Lab Practice
          </li>
          <li className=" tracking-wide text-lg flex items-center gap-1 pb-2">
            <LiaHandPointRight size="25px" color="#FF6700" />
            Free Programming Software
          </li>
          <li className=" tracking-wide text-lg flex items-center gap-1 pb-2">
            <LiaHandPointRight size="25px" color="#FF6700" />
            Flexible Course Duration
          </li>
        </div>
      </div>
      <FooterHeader />
    </div>
  );
}
