import React from "react";
import epdsolution from "../../assets/epd group/epd solution.jpg";
import { Link } from "react-router-dom";

export default function EpdSolution() {
  return (
    <div>
      {/* epd group */}
      <div className="epd_wrapper py-5 lg:flex md:flex">
        {/* left */}
        <div className="epd_left lg:w-[30%] lg:pr-20 md:px-10 lg:px-0  sm:px-2 px-2 pb-10 md:w-[30%]">
          <img src={epdsolution} alt="epd-group" />
        </div>
        {/* right */}
        <div className="epd_right flex-1">
          <Link to="/itServices" target="blank">
            <h2 className="text-5xl text-[#FF6700] underline font-semibold tracking-wide pb-5 lg:px-0 md:px-0 sm:px-2 px-2">
              EPD Solution
            </h2>
          </Link>
          <p className=" text-justify tracking-wide lg:px-0 md:px-0 sm:px-2 px-2 lg:pr-2 md:pr-2 sm:pr-2 pr-2">
            EPD IT SOLUTION is a dream . Day by day our world has been changed
            dramatically. Right now IT is the main focus over the world.Epd It
            Solution is a leading IT solutions provider that focuses on quality,
            innovation, & speed. We utilized technology to bring results to grow
            our clients businesses. we want to share IT experience and items
            with the general people in Gazipur . Not Only Gazipur now we are
            spreading our working area over the country. In 01 th jan, 2016 EPD
            IT started the journey but officially launched in 10 january, 2020.
            It’s really helpful for the IT Students over the country. Beside
            this we are providing website, software, Graphics work. We believe
            that student is the next generation. So we are trying to develop the
            student creativity by free and paid course.
          </p>

          <div className=" w-[95%] mx-auto h-[2px] bg-[#FF6700] mt-10 lg:px-0 md:px-0 sm:px-2 px-2"></div>
          {/* Experience */}
          <div className="flex gap-16 pt-10">
            <div className="flex items-center ">
              <h1 className=" lg:text-9xl md:text-9xl sm:text-5xl text-5xl pr-5 font-semibold text-[#FF6700] lg:px-0 md:px-0 sm:px-2 px-2">
                7
              </h1>
              <p className="lg:text-3xl md:text-3xl sm:text-lg text-lg font-bold text-[#FF6700]">
                Years of Experience
              </p>
            </div>
            <div className="flex items-center">
              <h2 className=" lg:text-9xl md:text-9xl sm:text-5xl text-5xl pr-5 font-semibold text-[#FF6700] lg:px-0 md:px-0 sm:px-2 px-2">
                32
              </h2>
              <p className="lg:text-3xl md:text-3xl sm:text-lg text-lg font-bold text-[#FF6700]">
                Awards & Honors
              </p>
            </div>
          </div>
          {/* Experience */}
        </div>
        {/* right */}
      </div>
      {/* epd group */}
    </div>
  );
}
