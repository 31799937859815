import React from "react";
import CourseDeuration from "./CourseDeuration";
import EpdItService from "../EpdItServices/EpdItService";
import Payment from "../../Components/Footer/Payment";
import FooterHeader from "../../Components/Footer/FooterHeader";

export default function Course() {
  return (
    <div>
      <div className="lg:w-[1140px] lg:mx-auto md:w-full sm:w-full w-full lg:px-0 md:px-2 sm:px-2 px-2">
        <div className="py-20">
          <h2 className="text-5xl font-semibold capitalize tracking-wide text-start text-[#FF6700] lg:px-0 md:px-2 sm:px-2 px-2 underline">
            কোর্স সমূহ
          </h2>
          <p className=" text-justify tracking-wide lg:px-0 md:px-2 sm:px-2 px-2 lg:pr-2 md:pr-2 sm:pr-2 pr-2 pt-5 text-xl font-thin leading-[34px] text-[#575757] font-sans">
            ইপিডি গ্রুপে বর্তমান বিশ্বের সব ট্রেন্ডি এবং চাহিদাসম্পন্ন সব কোর্স
            রয়েছে। কোর্স করার সময় যে প্রয়োজনীয় কনফিগারেশনের কম্পিউটার দরকার, তার
            সবই রয়েছে আমাদের ল্যাবে। আপনি চাইলেই ক্লাসের পরে ল্যাবে বসে কোর্স
            বিষয়ক যেকোনো কিছু প্র্যাকটিস করতে পারেন। প্রতিটি কোর্স এমনভাবে
            ডিজাইন করা হয়েছে যাতে আপনি কোর্স শেষে প্রতিটি টপিকে কাজ করার
            আত্মবিশ্বাস পান।
          </p>
        </div>
        {/* course */}
        <div></div>
        {/* course */}
        {/* CourseDeuration */}
        <div>
          <CourseDeuration />
        </div>
        {/* CourseDeuration */}
        {/* services */}
        <EpdItService />
        <Payment />
        {/* services */}
      </div>
      <FooterHeader />
    </div>
  );
}
